import React from 'react';
import Slider from '../../components/Slider';
import SpinButton from '../SpinButton';
import { formatNumberLocale } from '../../utils/utils';
import { useProductCart } from '../../hooks/useProductCart';
import { IProduct } from '../../api/types';
import Button from '../Button';
import { ReactComponent as ImagePlug } from '../../assets/image.svg';
import './ProductView.css';


type ProductViewProps = {
  product: IProduct;
};

const ProductView = ({ product }: ProductViewProps) => {
  const {cartValue, changeCartValue} = useProductCart(product);

  const addToCart = () => {
    changeCartValue(1);
  };

  return (   
    <div className="productView">
      <div className="productView__main">
          {product.images.length > 0 ? <Slider images={product.images} /> 
            : <div className="productView__image_plug"><ImagePlug /></div>}
        <div className="productView__brief">
          <div className="productView__price">
              {formatNumberLocale(product.prices.find(item => item.currency === 'RUB')?.price!, 2)} ₽
          </div>
          <div className="productView__sku">{product.sku ? `Код товара: ${product.sku}` : ''}</div>
        </div>
          <div className="productView__name">{product.name}</div>
        <div className="productView__about">{product.description}</div>
      </div>
      <div className="productView__footer">
        {cartValue === 0
          ? <Button caption='В корзину' onClick={addToCart} />
          : <SpinButton value={cartValue} onChange={changeCartValue} />
        }
      </div>
    </div>
  );
};

export default ProductView;
