import React from 'react';
import {Link} from 'react-router-dom';
import SpinButton from '../SpinButton';
import {IProduct} from '../../api/types';
import {formatNumberLocale} from '../../utils/utils';
import {useProductCart} from '../../hooks/useProductCart';
import {useAppSelector} from '../../store/store';
import {ReactComponent as ImagePlug} from '../../assets/image.svg';
import './ShopCard.css';
import Button from '../Button';


type ShopCardProps = {
    product: IProduct;
};

const ShopCard = ({product}: ShopCardProps) => {
    const {cartValue, changeCartValue} = useProductCart(product);
    const shopId = useAppSelector((state) => state.shopId);

    const addToCart = () => {
        changeCartValue(1);
    };

    return (
        <div className="shopProductCard">
            <Link className="shopProductCard__content"
                  to={`/${shopId}/product/${product.id}`} key={product.id}>
                {product.images.length > 0
                    ? <div className="shopProductCard__image"><img src={product.images[0]} alt=""/></div>
                    : <div className="shopProductCard__image_plug"><ImagePlug/></div>
                }
                <div className="shopProductCard__name">{product.name}</div>
                <div className="shopProductCard__price">
                    {formatNumberLocale(product.prices.find(item => item.currency === 'RUB')?.price!, 2)} ₽
                </div>
            </Link>
            <div className="shopProductCard__button">
                {cartValue === 0
                    ? <Button caption="В корзину" onClick={addToCart}/>
                    : <SpinButton value={cartValue} onChange={changeCartValue}/>
                }
            </div>
        </div>
    );
};

export default ShopCard;
