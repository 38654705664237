import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import ShopCard from '../../components/ShopCard';
import { useTelegram } from '../../hooks/useTelegram';
import { fetchProducts, setShopId, PRODUCTS_LIMIT } from '../../store/ShopSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import './Shop.css';


const Shop = () => {
  const dispatch = useAppDispatch();
  const { tg } = useTelegram();
  const params = useParams();
  const products = useAppSelector((state) => state.productsList)
  const cart = useAppSelector((state) => state.productsCart);
  const shopId = useAppSelector((state) => state.shopId);
  const hasMore = useAppSelector((state) => state.hasMore);
  const refList = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  const getProducts = useCallback(() => {
    const refer = refList.current;
    if (hasMore && refer && products.items) {
      if (refer.scrollHeight <= refer.scrollTop + refer.clientHeight) {
        dispatch(fetchProducts(
          { init_data: tg.initData, shop_uuid: shopId, limit: PRODUCTS_LIMIT, offset: products.items.length }));
      }
    }
  }, [hasMore, products, shopId, tg.initData, dispatch]);

  useEffect(() => {
    if (!loaded && products) {
      setLoaded(true);
      tg.ready();
    }
  }, [products, loaded, tg])

  useEffect(() => {
    if (tg.BackButton.isVisible) tg.BackButton.hide();
  });
  
  useEffect(()=> {
    if (params.shopId) {
      dispatch(setShopId(params.shopId));
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (shopId && !products.items) {
      dispatch(fetchProducts({ init_data: tg.initData, shop_uuid: shopId, limit: PRODUCTS_LIMIT }));
    }
  }, [shopId, products, tg.initData, dispatch]);

  if (products === undefined) return (<h1>No products</h1>);

  return (
    <div className="shop">
      <div className={`shop__list ${cart.length ? '':'empty'}`} ref={refList} onScroll={getProducts}>
        {products.items && products.items.map((product) => <ShopCard key={product.id} product={product} />)}
      </div>
      {shopId &&
        <div className="shop__bottom">
          <Link to={`/${shopId}/cart`}>
            {cart && cart.length > 0 && <Button caption='Перейти в корзину' />}
          </Link>
        </div>
      }
    </div>
  );
};

export default Shop;
