import axiosInstance from './axiosInstance';
import { ICartPostRequest, IProductsList, IProductsListRequest } from './types';


export const getProductsList = async (requestOptions: IProductsListRequest): Promise<IProductsList> =>
  axiosInstance.get(`/shops/${requestOptions.shop_uuid}/products/`,
    { params: { offset: requestOptions.offset, limit: requestOptions.limit },
      headers: { 'X-TG-INIT-DATA': requestOptions.init_data } });

export const sendCart = async (requestOptions: ICartPostRequest): Promise<string> => {
  const { shop_uuid, init_data, ...rest } = requestOptions;
  return axiosInstance.post(`/shops/${shop_uuid}/cart/`, { ...rest}, { headers: { 'X-TG-INIT-DATA': init_data } });
};
