/* eslint-disable @typescript-eslint/no-explicit-any */
const camelCase = require('lodash.camelcase');

export const convertor = (response: any): any => {
  if (response === null || response === undefined) {
    return response;
  }
  let convertedObj: any = {};

  if (typeof response !== 'object' && !Array.isArray(response)) {
    return response;
  }

  if (Array.isArray(response)) {
    return response.map((el) => convertor(el));
  }

  for (const key in response) {
    // eslint-disable-next-line no-prototype-builtins
    if (response.hasOwnProperty(key)) {
      const responseElement = response[key];

      if (Array.isArray(responseElement)) {
        convertedObj[camelCase(key)] = responseElement.map((el) => convertor(el));
      } else if (typeof responseElement === 'object' && responseElement !== null && !Array.isArray(responseElement)) {
        convertedObj = {
          ...convertedObj,
          [camelCase(key)]: convertor(responseElement),
        };
      } else {
        convertedObj[camelCase(key)] = responseElement;
      }
    }
  }
  return convertedObj;
};
