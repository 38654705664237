import React from 'react';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/minus.svg';
import './SpinButton.css';


type ButtonProps = {
  value: number;
  onChange: (value: number) => void;
};

const SpinButton = ({ value, onChange }: ButtonProps) => (
  <div className="spinButton">
    <button type='button' title='decrement' onClick={() => onChange(-1)}><MinusIcon /></button>
    {value}
    <button type='button' title='increment' onClick={() => onChange(1)}><PlusIcon /></button>
  </div>
);

export default SpinButton;
