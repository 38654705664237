import { useEffect, useState } from 'react';
import { IProduct } from '../api/types';
import { setProductCartValue } from '../store/ShopSlice';
import { useAppDispatch, useAppSelector } from '../store/store';


export const useProductCart = (product: IProduct) => {
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.productsCart);
  const [cartValue, setCartValue] = useState<number>(0);

  useEffect(() => {
    const productCart = cart.find((item) => item.product.id === product.id);
    if (productCart) {
      setCartValue(productCart.quantity);
    } else {
      setCartValue(0);
    }
  }, [product, cart]);

  const changeCartValue = (value: number) => {
    dispatch(setProductCartValue({ product: product, quantity: (cartValue + value )}));
  };

  return { cartValue, changeCartValue}
};