import React from 'react';
import './CartCard.css';
import SpinButton from '../SpinButton';
import { useAppDispatch } from '../../store/store';
import { setProductCartValue } from '../../store/ShopSlice';
import { IProductCart } from '../../api/types';
import { formatNumberLocale } from '../../utils/utils';
import { ReactComponent as ImagePlug } from '../../assets/image.svg';
import { ReactComponent as BasketIcon } from '../../assets/delete.svg';


type CartCardProps = {
  item: IProductCart;
};

const CartCard = ({ item }: CartCardProps) => {
  const dispatch = useAppDispatch();
  
  const changeCartValue = (value: number) => {
    dispatch(setProductCartValue({ product: item.product, quantity: (item.quantity + value )}));
  };

  const removeFromCart = () => {
    dispatch(setProductCartValue({ product: item.product, quantity: 0 }));
  }

    return (
        <div className="cartProductCard">
            {item.product.images.length > 0 ? <img src={item.product.images[0]} alt=""/>
                : <div className="cartProductCard__image_plug"><ImagePlug/></div>}
            <div className="cartProductCard__about">
                <div className="cartProductCard__info">
                    <div className="cartProductCard__namePrice">
                        <div className="cartProductCard__name">{item.product.name}</div>
                        <div className="cartProductCard__price">
                            {formatNumberLocale(item.product.prices.find(item => item.currency === 'RUB')?.price!, 2)} ₽
                        </div>
                    </div>
                    <div className="cartProductCard__countBasket">
                        <SpinButton value={item.quantity} onChange={changeCartValue}/>
                        <BasketIcon onClick={removeFromCart}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartCard;
