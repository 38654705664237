import React from 'react';
import './Button.css';


export type ButtonProps = {
  caption?: string;
  image?: React.ReactElement;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ caption, image, ...props}: ButtonProps) => (
  <button className="shopButton" {...props}>{image}{caption}</button>
);

export default Button;
