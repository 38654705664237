import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Cart from '../pages/Cart';
import Product from '../pages/Product';
import Shop from '../pages/Shop';

const AppRouter = () => (
  <Routes>
    <Route path='/:shopId' element={<Shop />} />
    <Route path='/:shopId/product/:id' element={<Product />} />
    <Route path='/:shopId/cart' element={<Cart />} />
  </Routes>
);

export default AppRouter;