import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import AppRouter from './router/router';
import shopStore from './store/store';
import { useTelegram } from './hooks/useTelegram';
import './App.css';


const App = () => {
  const { tg } = useTelegram();
  
  useEffect(() => {
    tg.expand();
  });

  return (
    <div className="App">
      <Provider store={shopStore}>
          <AppRouter />
      </Provider>
    </div>
  );
};

export default App;
