import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CartCard from '../../components/CartCard';
import { useTelegram } from '../../hooks/useTelegram';
import { handleCart } from '../../store/ShopSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { formatNumberLocale, getCartTotal } from '../../utils/utils';
import './Cart.css';


const Cart = () => {
  const dispatch = useAppDispatch();
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const commentRef = useRef<HTMLTextAreaElement>(null);
  const cart = useAppSelector((state) => state.productsCart);
  const shopId = useAppSelector((state) => state.shopId);
  const [cartTotal, setCartTotal] = useState<number>(0);

  const sendOrder = () => {
    dispatch(handleCart({
      init_data: tg.initData,
      shop_uuid: shopId,
      comment: commentRef.current?.value || '',
      currency: 'RUB',
      products: cart.map((item) => ({ product_id: item.product.id, quantity: item.quantity }))
    })).then(() => {tg.close();});
  };

  const goBack = useCallback(() => {
    tg.MainButton.hide();
    tg.BackButton.hide();
    navigate(-1);
  }, [tg, navigate]);

  useEffect(() => {
    if (!shopId) goBack();
  }, [shopId, goBack])

  useEffect(() => {
    tg.BackButton.show();
    tg.onEvent('backButtonClicked', goBack);
    return () => tg.offEvent('backButtonClicked', goBack);
  });

  useEffect(() => {
    setCartTotal(getCartTotal(cart));
  }, [cart]);

  useEffect(() => {
    if (cartTotal > 0) {
      tg.MainButton.show();
      tg.MainButton.setParams(
        { text: `Оформить заказ | ${formatNumberLocale(cartTotal, 2, 'ru')} ₽`,  text_color: '#FFFFFF',  color: '#FE6D04' });
    } else {
      tg.MainButton.hide();
    }
  }, [cartTotal, tg]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', sendOrder);
    return () => tg.offEvent('mainButtonClicked', sendOrder);
  });

  return (
    <div className="cart">
      <div>{cart.map((item) => <CartCard item={item} key={item.product.id} />)}</div>
      <fieldset className="cart_comment">
        <label>Комментарий к заказу</label>
        <textarea placeholder="Пожелания к заказу и прочее" ref={commentRef} rows={10}></textarea>
      </fieldset>
    </div>
  );
}

export default Cart;
