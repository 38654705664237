import React from 'react';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg';
import './Slider.css';


const arrows = {
  prevArrow: <div className='arrowSlide'><ArrowIcon /></div>,
  nextArrow: <div className='arrowSlide'><ArrowIcon /></div>
};

type SliderProps = {
  images: string[];
};

const Slider = ({ images }: SliderProps) => (
  <div className="slider">
    <Slide arrows={images.length > 1} {...arrows} autoplay={false} infinite={false} transitionDuration={400}>
      {images.map((image) => (
        <div className="slider__slide" key={image}>
          <img alt='product' src={image} />
        </div>
      ))}
    </Slide>
  </div>
);

export default Slider;
