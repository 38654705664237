import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProductsList, sendCart } from "../api/ShopAPI";
import { ICartPostRequest, IProduct, IProductCart, IProductsList, IProductsListRequest } from "../api/types";


export const PRODUCTS_LIMIT = 100;

export type ShopSlice = {
  productsList: IProductsList;
  productsCart: IProductCart[];
  shopId: string;
  hasMore: boolean;
};

const initialState: ShopSlice = {
  productsList: {} as IProductsList,
  productsCart: [],
  shopId: '',
  hasMore: true,
};

export const fetchProducts = createAsyncThunk<IProductsList, IProductsListRequest, { rejectValue: number }>(
  'shop/fetchProducts', async (requestOptions, { rejectWithValue }) => {
    try {
      return await getProductsList(requestOptions);
    } catch (e) {
      return rejectWithValue(400);
    }
  }
);

export const handleCart = createAsyncThunk<string, ICartPostRequest, { rejectValue: number }>(
  'shop/handleCart', async (requestOptions, {getState, rejectWithValue }) => {
    try {
      return await sendCart(requestOptions);
    } catch (e) {
      return rejectWithValue(400);
    }
  }
);

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    addProducts: (state, action: PayloadAction<IProduct[]>) => {
      state.productsList = { count: action.payload.length, items: action.payload }
    },
    deleteProductCart: (state, action: PayloadAction<IProduct>) => {
      state.productsCart = state.productsCart.filter((item) => item.product.id !== action.payload.id);
    },
    setShopId: (state, action: PayloadAction<string>) => {
      state.shopId = action.payload;
    },
    setProductCartValue: (state, action: PayloadAction<IProductCart>) => {
      const productIndex = state.productsCart.findIndex((item) => item.product.id === action.payload.product.id);
      if (productIndex !== -1) {
        if (action.payload.quantity <= 0) {
          state.productsCart = state.productsCart.filter((item) => item.product.id !== action.payload.product.id);
        } else {
          state.productsCart[productIndex] = action.payload;
        }
      } else {
        if (action.payload.quantity > 0) {
          state.productsCart.push(action.payload);
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.fulfilled, (state, action: PayloadAction<IProductsList>) => {
        if (state.productsList.items) {
          const itemsIds = state.productsList.items.map((item) => item.id);
          state.productsList.items.push(...action.payload.items.filter(item => !itemsIds.includes(item.id)));
        } else {
          state.productsList = action.payload;
        }
        state.hasMore = action.payload.count === PRODUCTS_LIMIT;
      })
  }
});

export const { addProducts, deleteProductCart, setProductCartValue, setShopId } = shopSlice.actions;
export default shopSlice.reducer;
