import { IProductCart } from "../api/types";


export const formatNumberLocale = (num: number, digits: number = 2, locale?: string): string => {
  let value: string;
  let precision = digits;
  if (num % 1 === 0) {
    value =  num.toString();
    precision = 0;
  } else {
    value = num.toFixed(digits);
  }
  if (locale !== undefined) {
    return parseFloat(value).toLocaleString(locale, { minimumFractionDigits: precision });
  }
  return value;
};


export const getCartTotal = (cart: IProductCart[]) => {
  return cart.reduce((amount, item) => {
    const priceItem = item.product.prices.find((price) => price.currency === 'RUB')
    if (priceItem) {
      return amount + priceItem.price * item.quantity ;
    }
    return amount;
  }, 0);
};
