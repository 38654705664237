import React, { useCallback, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTelegram } from '../../hooks/useTelegram';
import { useAppSelector } from '../../store/store';
import ProductView from '../../components/ProductView';
import './Product.css';


const Product = () => {
  const params = useParams();
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const products = useAppSelector((state) => state.productsList);
  const product = products?.items?.find((item) => item.id === Number(params.id));
  
  const goBack = useCallback(() => {
    tg.BackButton.hide();
    navigate(-1);
  }, [tg.BackButton, navigate]);

  useEffect(() => {
    tg.BackButton.show();
    tg.onEvent('backButtonClicked', goBack);
    return () => tg.offEvent('backButtonClicked', goBack);
  });

  if (params.shopId && (!products.count || !product)) {
    return <Navigate to={`/${params.shopId}`} />;
  }

  return (
    <>
    {product &&
        <ProductView product={product} />
    }
    </>
  );
};

export default Product;
